@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-white antialiased font-main no-scrollbar;
  } 
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none
  }
}
@font-face {
  font-family: "Main";
  src: url("../public/fonts/Magnat-TextLight.otf");
}

@font-face {
  font-family: "Bold";
  src: url("../public/fonts/Magnat-TextMedium.otf");
}

